import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { creativesConstants } from './constants';
import { Option } from '../../models/Option';
import { Creative } from '../../models/Creative';

export interface CreativesState {
  creativeTypes: Option<number>[];
  selectedCreativeType: Option<number> | null;
  creatives: Creative[];
  selectedCreatives: Creative[];
}

const defaultCreativesState: CreativesState = {
  creativeTypes: [],
  selectedCreativeType: null,
  creatives: [],
  selectedCreatives: [],
};

function setCreativeTypes(state: CreativesState, action: Action<Option<number>[]>): CreativesState {
  return {
    ...state,
    creativeTypes: action.payload,
  };
}

function selectCreativeType(state: CreativesState, action: Action<Option<number>>): CreativesState {
  return {
    ...state,
    selectedCreativeType: action.payload,
  };
}

function setCreatives(state: CreativesState, action: Action<Creative[]>): CreativesState {
  return {
    ...state,
    creatives: action.payload,
  };
}

function selectCreatives(state: CreativesState, action: Action<Creative[]>): CreativesState {
  return {
    ...state,
    selectedCreatives: action.payload,
  };
}

function reset(): CreativesState {
  return {
    ...defaultCreativesState,
  };
}

const reducer = reducerFromMap<CreativesState>(defaultCreativesState, {
  [creativesConstants.SET_CREATIVE_TYPES]: setCreativeTypes,
  [creativesConstants.SELECT_CREATIVE_TYPE]: selectCreativeType,
  [creativesConstants.SET_CREATIVES]: setCreatives,
  [creativesConstants.SELECT_CREATIVES]: selectCreatives,
  [creativesConstants.RESET_CREATIVE_STORE]: reset,
});

export const creatives = (state: CreativesState = defaultCreativesState, action: Action<any>) =>
  reducer(state, action);
