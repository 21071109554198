import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { logWarningXSS } from './utils/logger';

import messagesRu from './translations/ru.json';
import messagesEn from './translations/en.json';

import { App } from './App';
import { store } from './store';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  logWarningXSS();
}

const language: string = navigator.language.split(/[-_]/)[0];

interface IMessages {
  [key: string]: {
    [key: string]: string;
  };
}

const messages: IMessages = {
  ru: messagesRu,
  en: messagesEn,
};

ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <Provider store={store}>
      <App />
    </Provider>
  </IntlProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
