import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { ErrorCreatingResponse } from '../../models/Response';
import { applicationConstants } from './constants';

export interface ApplicationState {
  sidebarOpened: boolean;
  submitted: boolean;
  loading: boolean;
  errorCreating: ErrorCreatingResponse | null;
  campaignCreated: boolean;
}

const defaultApplicationState: ApplicationState = {
  sidebarOpened: true,
  submitted: false,
  loading: false,
  errorCreating: null,
  campaignCreated: false,
};

function toggleSidebar(state: ApplicationState): ApplicationState {
  return {
    ...state,
    sidebarOpened: !state.sidebarOpened,
  };
}

function setSubmitted(state: ApplicationState): ApplicationState {
  return {
    ...state,
    submitted: true,
    errorCreating: null,
  };
}

function startCreating(state: ApplicationState): ApplicationState {
  return {
    ...state,
    loading: true,
    errorCreating: null,
    campaignCreated: false,
  };
}

function successCreating(state: ApplicationState): ApplicationState {
  return {
    ...state,
    loading: false,
    submitted: false,
    campaignCreated: true,
  };
}

function failCreating(state: ApplicationState, action: Action<any>): ApplicationState {
  return {
    ...state,
    loading: false,
    submitted: false,
    errorCreating: action.payload || true,
  };
}

function resetError(state: ApplicationState): ApplicationState {
  return {
    ...state,
    errorCreating: null,
  };
}

function reset(state: ApplicationState): ApplicationState {
  return {
    ...defaultApplicationState,
    campaignCreated: state.campaignCreated,
  };
}

const reducer = reducerFromMap<ApplicationState>(defaultApplicationState, {
  [applicationConstants.TOGGLE__SIDEBAR_MENU]: toggleSidebar,
  [applicationConstants.SET_SUBMITTED_STATE]: setSubmitted,
  [applicationConstants.START_CREATING]: startCreating,
  [applicationConstants.SUCCESS_CREATING]: successCreating,
  [applicationConstants.FAIL_CREATING]: failCreating,
  [applicationConstants.RESET_ERROR]: resetError,
  [applicationConstants.RESET_APP_STORE]: reset,
});

export const app = (state: ApplicationState = defaultApplicationState, action: Action<any>) =>
  reducer(state, action);
