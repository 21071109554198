import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import { getInstance } from './Instance';
import { Logger } from '../utils/logger';

export type PoliticalAdvertiser = {
  advertiserId: number;
  dspId: number;
  id: number;
  name: string;
  address: string;
  phone: string;
  website: string;
  chiefName: string;
  legalInfo: string;
  isActive: number;
  uuid: string;
  links: string;
};

type Params = {
  advertiserId: number;
  dspId: number;
};

export const fetchAdvertiserList = async (params: Params): Promise<PoliticalAdvertiser | null> => {
  try {
    const response: AxiosResponse<any> = await getInstance().get('/v2/adv/politicalclients', {
      params,
    });
    const advDetails = get(response, 'data.responseObject.details', null);
    if (advDetails) {
      const keys = Object.keys(advDetails);
      return advDetails[keys[0]];
    }
    return advDetails;
  } catch (e) {
    Logger.log('Error while get political advertiser list:', e);
    return null;
  }
};

export type CreatingParams = {
  dspId: number;
  advertiserId: number;
  name: string;
  address: string;
  phone: string;
  website: string;
  chiefName: string;
  legalInfo: string;
  links: string;
};

export const createPoliticalAdvertiser = async (req: CreatingParams): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await getInstance().post('/v2/adv/politicalclients', req);
    return get(response, 'data.responseObject.details', null);
  } catch (e) {
    Logger.log('Error while creating political advertiser:', e);
    return Promise.reject(e);
  }
};
