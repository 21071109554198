import React from 'react';
import { connect } from 'react-redux';
import { IQMRouter } from 'iqm-framework';

import { authActions, Authorized, Logout } from './store/auth/actions';
import { BASE_URL } from './config';
import { User } from './models/User';
import { MainPage } from './pages/MainPage';

import './App.scss';
import { advertiserActions } from './store/advertiser/actions';

type Props = Authorized &
  Logout & {
    getAdvertiserData: () => void;
  };

const AppComponent = (props: Props) => {
  const { authorized, logout, getAdvertiserData } = props;

  const onSuccessLogin = (data: User) => {
    authorized(data);
    getAdvertiserData();
  };

  const onLogout = () => logout();

  return (
    <IQMRouter
      documentTitle="Campaigns | IQM Corporation"
      successLoginRedirectPath={BASE_URL}
      onSuccessLogin={onSuccessLogin}
      onLogout={onLogout}
    >
      <MainPage />
    </IQMRouter>
  );
};

const mapAction = {
  authorized: authActions.authorized,
  logout: authActions.logout,
  getAdvertiserData: advertiserActions.getAdvertiserData,
};

export const App = connect(null, mapAction)(AppComponent);
