import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { auth, AuthState } from './auth/reducer';
import { app, ApplicationState } from './app/reducer';
import { advanced, AdvancePageState } from './advance/reducer';
import { map, MapState } from './map/reducer';
import { creatives, CreativesState } from './creatives/reducer';
import { publishers, PublishersState } from './publishers/reducer';
import { advertiser, AdvertiserState } from './advertiser/reducer';
import { technology, TechnologyStore } from './tecnology/reducer';
import { bid, BidStore } from './bid/reducer';
import { demographic, DemographicState } from './demographic/reducer';

export interface AppState {
  auth: AuthState;
  app: ApplicationState;
  advanced: AdvancePageState;
  map: MapState;
  creatives: CreativesState;
  publishers: PublishersState;
  advertiser: AdvertiserState;
  technology: TechnologyStore;
  bid: BidStore;
  demographic: DemographicState;
}

const reducer = combineReducers({
  auth,
  app,
  advanced,
  map,
  creatives,
  publishers,
  advertiser,
  technology,
  bid,
  demographic,
});

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore<any, any, any, any>(
  reducer,
  composeEnhancers(applyMiddleware(thunk)),
);
