import { Dispatch } from 'redux';
import get from 'lodash/get';
import { createAction } from '../../utils/actions';
import { advertiserConstants } from './constants';
import { validateUrl, validateMultiUrl, validatePhone } from '../../utils/validationRules';
import { AppState } from '..';
import { API } from '../../api';
import { PoliticalAdvertiser, CreatingParams } from '../../api/Advertiser';
import { applicationConstants } from '../app/constants';

export interface SetAdvertiserField {
  setAdvertiserField: (data: { key: string; value: string | boolean }) => void;
}

export interface SetAdvertisingStatus {
  setAdvertisingStatus: (value: boolean) => void;
}

export interface ResetAdvertiserState {
  resetAdvertiserState: () => void;
}

export interface ValidateAdvertiserForm {
  validateAdvertiserForm: () => boolean;
}

export const advertiserActions = {
  setAdvertiserField(data: { key: string; value: string | boolean }) {
    return createAction<any>(advertiserConstants.SET_FIELD, data);
  },

  setAdvertisingStatus(value: boolean) {
    return createAction<boolean>(advertiserConstants.SET_POLITICAL_ADV_STATUS, value);
  },

  getAdvertiserData() {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const state = getState();
      const { user } = state.auth;
      if (user) {
        const params = {
          advertiserId: user.userId,
          dspId: user.dspId,
        };
        const response: PoliticalAdvertiser | null = await API.Advertiser.fetchAdvertiserList(
          params,
        );
        if (response) {
          dispatch(
            createAction<PoliticalAdvertiser>(
              advertiserConstants.SET_POLITICAL_ADVERTISER,
              response,
            ),
          );
        }
      }
    };
  },

  createAdvertiser() {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(createAction(applicationConstants.START_CREATING));
      try {
        const state = getState();
        const { auth, advertiser } = state;
        if (auth.user) {
          const request: CreatingParams = {
            advertiserId: auth.user.userId,
            dspId: auth.user.dspId,
            name: advertiser.name,
            address: advertiser.address,
            phone: advertiser.phone,
            website: advertiser.url,
            chiefName: advertiser.chief,
            links: advertiser.links,
            legalInfo: advertiser.other,
          };
          await API.Advertiser.createPoliticalAdvertiser(request);
        }
        return Promise.resolve();
      } catch (error) {
        const errorObj = get(error, 'responseObject', null);
        dispatch(createAction<any>(applicationConstants.FAIL_CREATING, errorObj || error));
        return Promise.reject();
      }
    };
  },

  resetAdvertiserState() {
    return createAction(advertiserConstants.RESET);
  },

  validateAdvertiserForm() {
    return (_dispatch: Dispatch, getState: () => AppState) => {
      const state = getState();
      const { advertiser } = state;
      if (!advertiser.isPoliticalAdvertising) {
        return true;
      }
      return !!(
        advertiser.name.trim().length &&
        advertiser.address.length &&
        advertiser.chief.length &&
        advertiser.url.length &&
        validateUrl(true).func(advertiser.url) &&
        advertiser.phone.length &&
        validatePhone(true).func(advertiser.phone) &&
        validateMultiUrl(true).func(advertiser.links) &&
        advertiser.other.length
      );
    };
  },
};
