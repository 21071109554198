/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';

export interface PublisherResponse {
  id: number;
  parent_id: number;
  name: string;
  group: string;
  iab_id: string;
}

export const FetchPublishers = async (): Promise<PublisherResponse[]> => {
  try {
    const response: AxiosResponse<PublisherResponse[]> = await getInstance().get('/adcategories');

    return response.data;
  } catch (e) {
    Logger.log('Error while get creative types', e);
    return [];
  }
};
