import moment from 'moment';

import { Option, OptionId } from '../../models/Option';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { advanceConstants } from './constants';
import { CampaignInfoField } from '../../models/CampaignInfoFields';

export interface AdvancePageState {
  budgetPacing: boolean;
  states: Option<number>[];
  sidebarCampaignInfo: {
    [CampaignInfoField.name]: string;
    [CampaignInfoField.group]: Option[];
    [CampaignInfoField.totalBudget]: string;
    [CampaignInfoField.dailyBudget]: string;
    [CampaignInfoField.maxBid]: string;
    [CampaignInfoField.timezone]?: OptionId;
    [CampaignInfoField.start]: number;
    [CampaignInfoField.end]: number;
    [CampaignInfoField.country]?: Option<number>;
    [CampaignInfoField.states]: Option<number>[];
    [CampaignInfoField.domain]: string;
  };
}

const defaultAdvancePageState: AdvancePageState = {
  budgetPacing: true,
  states: [],
  sidebarCampaignInfo: {
    [CampaignInfoField.name]: '',
    [CampaignInfoField.group]: [],
    [CampaignInfoField.totalBudget]: '',
    [CampaignInfoField.dailyBudget]: '',
    [CampaignInfoField.maxBid]: '',
    [CampaignInfoField.timezone]: undefined,
    [CampaignInfoField.start]: moment().valueOf(),
    [CampaignInfoField.end]: moment()
      .add(1, 'd')
      .valueOf(),
    [CampaignInfoField.country]: undefined,
    [CampaignInfoField.states]: [],
    [CampaignInfoField.domain]: '',
  },
};

const toggleBudgetPacing = (state: AdvancePageState): AdvancePageState => {
  return {
    ...state,
    budgetPacing: !state.budgetPacing,
  };
};

const setStates = (
  state: AdvancePageState,
  action: Action<Option<number>[]>,
): AdvancePageState => ({
  ...state,
  states: action.payload,
});

const setSidebarCampaignInfo = (
  state: AdvancePageState,
  action: Action<{ key: string; value: any }>,
): AdvancePageState => {
  const { key, value } = action.payload;
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [key]: value,
    },
  };
};

const reset = (): AdvancePageState => {
  return {
    ...defaultAdvancePageState,
  };
};

const reducer = reducerFromMap<AdvancePageState>(defaultAdvancePageState, {
  [advanceConstants.TOGGLE_BUDGET_PACING]: toggleBudgetPacing,
  [advanceConstants.SET_STATES]: setStates,
  [advanceConstants.SIDEBAR_CAMPAIGN_INFO__SET]: setSidebarCampaignInfo,
  [advanceConstants.RESET_ADVANCE_STORE]: reset,
});

export const advanced = (state: AdvancePageState = defaultAdvancePageState, actions: Action<any>) =>
  reducer(state, actions);
