import { AxiosResponse } from 'axios';
import { getInstance } from './Instance';
import { Logger } from '../utils/logger';
import { InventoryGroup } from '../models/Inventory';

export const Groups = async (): Promise<InventoryGroup[]> => {
  try {
    const response: AxiosResponse<InventoryGroup[]> = await getInstance().get('/app_groups/0');

    return response.data;
  } catch (e) {
    Logger.log('Error while get inventory group list', e);
    return [];
  }
};
