import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { demographicConstants } from './constants';
import { Option } from '../../models/Option';
import { CampaignInfoField } from '../../models/CampaignInfoFields';

export interface DemographicState {
  age: Option<number>[];
  gender: Option<number>[];
  language: Option<number>[];
  interest: Option<number>[];
  incomeRange: Option<number>[];
  nationality: Option<number>[];
  sidebarCampaignInfo: {
    [CampaignInfoField.age]: Option<number>[];
    [CampaignInfoField.gender]: Option<number>[];
    [CampaignInfoField.language]: Option<number>[];
    [CampaignInfoField.interest]: Option<number>[];
    [CampaignInfoField.incomeRange]: Option<number>[];
    [CampaignInfoField.nationality]: Option<number>[];
  };
}

const defaultDemographicState: DemographicState = {
  age: [],
  gender: [],
  language: [],
  interest: [],
  incomeRange: [],
  nationality: [],
  sidebarCampaignInfo: {
    [CampaignInfoField.age]: [],
    [CampaignInfoField.gender]: [],
    [CampaignInfoField.language]: [],
    [CampaignInfoField.interest]: [],
    [CampaignInfoField.incomeRange]: [],
    [CampaignInfoField.nationality]: [],
  },
};

const setStoreField = (
  state: DemographicState,
  action: Action<{ key: string; value: Option<number>[] }>,
): DemographicState => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value,
  };
};

const setSidebarCampaignInfo = (
  state: DemographicState,
  action: Action<{ key: string; value: Option<number>[] }>,
): DemographicState => {
  const { key, value } = action.payload;
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [key]: value,
    },
  };
};

const resetDemographicState = (): DemographicState => {
  return defaultDemographicState;
};

const reducer = reducerFromMap<DemographicState>(defaultDemographicState, {
  [demographicConstants.SET_DEMOGRAPHIC_FIELD]: setStoreField,
  [demographicConstants.SELECT_DEMOGRAPHIC_FIELD]: setSidebarCampaignInfo,
  [demographicConstants.RESET_DEMOGRAPHIC_STORE]: resetDemographicState,
});

export const demographic = (
  state: DemographicState = defaultDemographicState,
  action: Action<any>,
) => reducer(state, action);
