import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { advertiserConstants } from './constants';
import { PoliticalAdvertiser } from '../../api/Advertiser';

export interface AdvertiserInfoForm {
  name: string;
  address: string;
  phone: string;
  url: string;
  chief: string;
  links: string;
  other: string;
}

export interface AdvertiserState extends AdvertiserInfoForm {
  isPoliticalAdvertising: boolean;
  politicalAdvertiser: PoliticalAdvertiser | null;
  isDefault: boolean;
}

const defaultAdvertiserState: AdvertiserState = {
  isPoliticalAdvertising: false,
  name: '',
  address: '',
  phone: '',
  url: '',
  chief: '',
  links: '',
  other: '',
  isDefault: false,
  politicalAdvertiser: null,
};

const setAdvertiserInfo = (
  state: AdvertiserState,
  action: Action<{ key: string; value: any }>,
): AdvertiserState => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value,
  };
};

const changePoliticalAdvStatus = (state: AdvertiserState, action: Action<boolean>) => ({
  ...state,
  isPoliticalAdvertising: action.payload,
});

const setPoliticalAdvertiser = (state: AdvertiserState, action: Action<PoliticalAdvertiser>) => ({
  ...state,
  politicalAdvertiser: action.payload,
});

const reset = (): AdvertiserState => {
  return {
    ...defaultAdvertiserState,
  };
};

const reducer = reducerFromMap<AdvertiserState>(defaultAdvertiserState, {
  [advertiserConstants.SET_FIELD]: setAdvertiserInfo,
  [advertiserConstants.SET_POLITICAL_ADV_STATUS]: changePoliticalAdvStatus,
  [advertiserConstants.SET_POLITICAL_ADVERTISER]: setPoliticalAdvertiser,
  [advertiserConstants.RESET]: reset,
});

export const advertiser = (state: AdvertiserState = defaultAdvertiserState, action: Action<any>) =>
  reducer(state, action);
