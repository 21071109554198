import React from 'react';
import { Header } from 'iqm-framework';
import styles from './NavigationWrapper.module.scss';

export class NavigationWrapper extends React.Component {
  handleLogout = () => {
    document.location.reload();
  };

  render() {
    return (
      <Header
        backButton={false}
        title="Campaigns"
        leftLogo="Campaign Builder"
        onLogout={this.handleLogout}
      >
        <div id="topNavButtons" className={styles.buttons} />
      </Header>
    );
  }
}
