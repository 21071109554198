import { AxiosResponse } from 'axios';
import { getInstance } from './Instance';
import { Logger } from '../utils/logger';
import { DealGroup } from '../models/DealGroup';

export const getItems = async (): Promise<DealGroup[]> => {
  try {
    const response: AxiosResponse<DealGroup[]> = await getInstance().get('/deal_group_list');
    return response.data;
  } catch (e) {
    Logger.log('Error while get deal group list', e);
    return [];
  }
};
