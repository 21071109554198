/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import get from 'lodash/get';

import { Logger } from '../utils/logger';
import { getInstance } from './Instance';

interface IResponse {
  campaign_id: number;
  end_date: number;
}

export type CreatingCampaignParams = {
  advertiserId: number;
  dspId: number;
  isAgreementChecked: boolean;
  campaignName: string;
  countryId: string;
  status: string;
  creativeType: number;
  campaignType: number;
  advertiserDomain: string;
  configObject: string;
  budgetTotal: number;
  maxBid: number;
  startTime: number;
  endTime: number;
  timezone: number;
  creativeIds: string;
  budgetDay?: number;
  bidOptimization?: boolean;
  bidPacing?: boolean;
  impressionCapping?: number;
  maxDayClicks?: number;
  totalClicks?: number;
  maxDayImpressions?: number;
  totalImpressions?: number;
  maxDayConversions?: number;
  totalConversions?: number;
  conversionType?: 'install' | 'non-install';
  appURL?: string;
  carriers?: string;
  networkType?: string;
  deviceType?: string;
  trafficType?: string;
  manufacturer?: string;
  os?: string;
  osVersion?: string;
  device?: string;
  totalBudgetPacing?: boolean;
  publisherAdCategory?: string;
  campaignGroupIds?: string;
  targetCPI?: number;
};

export const CreateCampaign = async (req: CreatingCampaignParams): Promise<IResponse> => {
  try {
    const response: AxiosResponse<IResponse> = await getInstance().post('/campaigns', req);

    return response.data;
  } catch (e) {
    Logger.log('Error while get creative types', e);
    return e;
  }
};

export const CreateCampaignNew = async (req: CreatingCampaignParams): Promise<IResponse> => {
  try {
    const response: AxiosResponse<IResponse> = await getInstance().post(
      '/v2/adv/campaigns/add',
      req,
    );

    return response.data;
  } catch (e) {
    Logger.log('Error while get creative types', e);
    return Promise.reject(get(e, 'response.data', e));
  }
};
