import { AxiosResponse } from 'axios';
import { getInstance } from './Instance';
import { Logger } from '../utils/logger';
import { Exchange } from '../models/Exchange';

export const getItems = async (): Promise<Exchange[]> => {
  try {
    const response: AxiosResponse<Exchange[]> = await getInstance().get('/exchanges');
    return response.data;
  } catch (e) {
    Logger.log('Error while get exchanges:', e);
    return [];
  }
};
