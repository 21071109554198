import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { bidConstants } from './constants';

export interface BidStore {
  conversionsOptimization: boolean;
  targetCpiCpa: string;
  conversionsType: boolean;
  bidOptimization: boolean;
  impressionsCapping: boolean;
  impressionsCappingValue: number;
  budgetCapping: boolean;
  impressionsMaxDaily: string;
  impressionsMaxTotal: string;
  clicksMaxDaily: string;
  clicksMaxTotal: string;
  conversionsMaxDaily: string;
  conversionsMaxTotal: string;
  bidPacing: boolean;
  appUrl: string;
}

const defaultBidStore: BidStore = {
  bidOptimization: true,
  impressionsCapping: false,
  impressionsCappingValue: 1,
  conversionsOptimization: false,
  targetCpiCpa: '',
  conversionsType: true,
  budgetCapping: false,
  impressionsMaxDaily: '',
  impressionsMaxTotal: '',
  clicksMaxDaily: '',
  clicksMaxTotal: '',
  conversionsMaxDaily: '',
  conversionsMaxTotal: '',
  bidPacing: true,
  appUrl: '',
};

const changeValue = (
  state: BidStore,
  action: Action<{ key: string; value: number | boolean }>,
): BidStore => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value,
  };
};

const resetBidStore = (): BidStore => {
  return defaultBidStore;
};

const reducer = reducerFromMap<BidStore>(defaultBidStore, {
  [bidConstants.CHANGE_BID_FIELD]: changeValue,
  [bidConstants.RESET_BID_STORE]: resetBidStore,
});

export const bid = (state: BidStore = defaultBidStore, action: Action<any>) =>
  reducer(state, action);
