import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { technologyConstants } from './constants';
import { Option } from '../../models/Option';
import { CampaignInfoField } from '../../models/CampaignInfoFields';

export interface TechnologyStore {
  deviceTypes: Option<number>[];
  manufacturers: Option<number>[];
  os: Option<number>[];
  carriers: Option<number>[];
  network: Option<number>[];
  traffic: Option<number>[];
  sidebarCampaignInfo: {
    [CampaignInfoField.deviceTypes]: Option<number>[];
    [CampaignInfoField.manufacturers]: Option<number>[];
    [CampaignInfoField.os]: Option<number>[];
    [CampaignInfoField.carriers]: Option<number>[];
    [CampaignInfoField.network]: Option<number>[];
    [CampaignInfoField.traffic]: Option<number>[];
  };
}

const defaultTechnologyState: TechnologyStore = {
  deviceTypes: [],
  manufacturers: [],
  os: [],
  carriers: [],
  network: [],
  traffic: [],
  sidebarCampaignInfo: {
    [CampaignInfoField.deviceTypes]: [],
    [CampaignInfoField.manufacturers]: [],
    [CampaignInfoField.os]: [],
    [CampaignInfoField.carriers]: [],
    [CampaignInfoField.network]: [],
    [CampaignInfoField.traffic]: [],
  },
};

const setStoreField = (
  state: TechnologyStore,
  action: Action<{ key: string; value: Option<number>[] }>,
): TechnologyStore => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value,
  };
};

const setSidebarCampaignInfo = (
  state: TechnologyStore,
  action: Action<{ key: string; value: Option<number>[] }>,
): TechnologyStore => {
  const { key, value } = action.payload;
  return {
    ...state,
    sidebarCampaignInfo: {
      ...state.sidebarCampaignInfo,
      [key]: value,
    },
  };
};

const resetTechnologyStore = (): TechnologyStore => {
  return defaultTechnologyState;
};

const reducer = reducerFromMap<TechnologyStore>(defaultTechnologyState, {
  [technologyConstants.SET_TECHNOLOGY_FIELD]: setStoreField,
  [technologyConstants.SELECT_TECHNOLOGY_FIELD]: setSidebarCampaignInfo,
  [technologyConstants.RESET_TECHNOLOGY_STORE]: resetTechnologyStore,
});

export const technology = (state: TechnologyStore = defaultTechnologyState, action: Action<any>) =>
  reducer(state, action);
