export enum CampaignInfoField {
  name = 'campaignName',
  group = 'campaignGroups',
  totalBudget = 'totalBudget',
  dailyBudget = 'dailyBudget',
  maxBid = 'maxBid',
  timezone = 'timezone',
  start = 'startDate',
  end = 'endDate',
  country = 'country',
  domain = 'domain',
  publishers = 'selectedPublishers',
  deviceTypes = 'selectedDeviceTypes',
  os = 'selectedOperetingSystems',
  carriers = 'selectedCarriers',
  network = 'selectedNetworkTypes',
  traffic = 'selectedTrafficTypes',
  manufacturers = 'selectedManufacturers',
  states = 'states',
  age = 'selectedAge',
  gender = 'selectedGender',
  language = 'selectedLanguage',
  interest = 'selectedInterest',
  incomeRange = 'selectedIncomeRange',
  nationality = 'selectedNationality',
}
