import { AxiosResponse } from 'axios';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import { Option } from '../models/Option';

interface IResponse {
  name: string;
  id: number;
}

// export interface CreativeParams {
//   dspId: number;
//   advertiserId: number;
//   creativeTypeIds?: number;
//   searchField?: string;
//   noOfEntries?: number;
//   pageNo?: number;
//   sortBy?: string;
//   sortType?: 'asc' | 'desc';
// }

export const FetchCreativeTypes = async (): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<IResponse[]> = await getInstance().get('/creative_types');

    return response.data.map((i) => ({
      label: i.name,
      value: i.id,
    }));
  } catch (e) {
    Logger.log('Error while get creative types', e);
    return [];
  }
};
