/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { Country } from '../models/Country';
import { Logger } from '../utils/logger';
import { getInstance } from './Instance';
import { GetResponse } from '../models/Response';
import { Timezone } from '../models/Timezone';
import { DeviceType } from '../models/Device';
import { Dictionary } from '../models/Dictionary';
import { Option } from '../models/Option';
import {
  convertDistrictCodeFromDTO,
  convertLocationStateFromDTO,
  DistrictCodeDTO,
  LocationState,
  LocationStateDTO,
  DmaCodeDTO,
  convertDmaCodesFromDTO,
} from '../models/Location';

let countriesPromise: Promise<AxiosResponse<GetResponse<Country[]>>>;
let timezonesPromise: Promise<AxiosResponse<GetResponse<Timezone[]>>>;
let deviceTypesPromise: Promise<AxiosResponse<DeviceType[]>>;
let ageRangePromise: Promise<AxiosResponse<Dictionary[]>>;
let genderPromise: Promise<AxiosResponse<Dictionary[]>>;
let languagePromise: Promise<AxiosResponse<Dictionary[]>>;
let interestsPromise: Promise<AxiosResponse<Dictionary[]>>;
let incomeRangePromise: Promise<AxiosResponse<Dictionary[]>>;
let ethnicityPromise: Promise<AxiosResponse<Dictionary[]>>;
let carriersPromise: Promise<AxiosResponse<Dictionary[]>>;
let networkTypesPromise: Promise<AxiosResponse<Dictionary[]>>;
let trafficTypesPromise: Promise<AxiosResponse<Dictionary[]>>;
let manufacturersPromise: Promise<AxiosResponse<Dictionary[]>>;
let deviceOsPromise: Promise<AxiosResponse<Dictionary[]>>;
let districtCodesPromise: Promise<AxiosResponse<DistrictCodeDTO[]>>;
let senateStatesPromise: Promise<AxiosResponse<LocationStateDTO[]>>;
let houseStatesPromise: Promise<AxiosResponse<LocationStateDTO[]>>;
let dmaCodesPromise: Promise<AxiosResponse<DmaCodeDTO[]>>;

export const Countries = async (): Promise<Country[]> => {
  try {
    const response: AxiosResponse<GetResponse<Country[]>> = await (countriesPromise ||
      (countriesPromise = getInstance().get('/v2/adv/static/country/list')));

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while get countries', e);
    return [];
  }
};

export const States = async (countryCode: number = 246): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<any> = await getInstance().get(
      `/countries/${countryCode}/states`,
    );

    return response.data.map((state: any) => ({
      label: state.name,
      value: state.id,
    }));
  } catch (e) {
    Logger.log('Error while get states', e);
    return [];
  }
};

export const Timezones = async (): Promise<Timezone[]> => {
  try {
    const response: AxiosResponse<GetResponse<Timezone[]>> = await (timezonesPromise ||
      (timezonesPromise = getInstance().get('/v2/adv/static/timezone/list')));

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while get timezones', e);
    return [];
  }
};

export const DeviceTypes = async (): Promise<DeviceType[]> => {
  try {
    const response: AxiosResponse<DeviceType[]> = await (deviceTypesPromise ||
      (deviceTypesPromise = getInstance().get('/device_types')));

    return response.data;
  } catch (e) {
    Logger.log('Error while get device types', e);
    return [];
  }
};

export const AgeRange = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (ageRangePromise ||
      (ageRangePromise = getInstance().get('/users/age_range')));

    return response.data;
  } catch (e) {
    Logger.log('Error while get age range list', e);
    return [];
  }
};

export const Gender = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (genderPromise ||
      (genderPromise = getInstance().get('/users/gender')));

    return response.data;
  } catch (e) {
    Logger.log('Error while get gender list', e);
    return [];
  }
};

export const Language = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (languagePromise ||
      (languagePromise = getInstance().get('/users/language')));

    return response.data;
  } catch (e) {
    Logger.log('Error while get language list', e);
    return [];
  }
};

export const Interests = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (interestsPromise ||
      (interestsPromise = getInstance().get('/users/interest')));

    return response.data;
  } catch (e) {
    Logger.log('Error while get interests list', e);
    return [];
  }
};

export const IncomeRange = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (incomeRangePromise ||
      (incomeRangePromise = getInstance().get('/users/income_range')));

    return response.data;
  } catch (e) {
    Logger.log('Error while get income range list', e);
    return [];
  }
};

export const Ethnicity = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (ethnicityPromise ||
      (ethnicityPromise = getInstance().get('/users/ethnicity')));

    return response.data;
  } catch (e) {
    Logger.log('Error while get ethnicity list', e);
    return [];
  }
};

// TODO: Fix with real data. What country ID we need to use?
export const Carriers = async (countryId: string | number): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (carriersPromise ||
      (carriersPromise = getInstance().get(`/countries/${countryId}/carriers`)));

    return response.data;
  } catch (e) {
    Logger.log('Error while get carriers list', e);
    return [];
  }
};

export const NetworkTypes = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (networkTypesPromise ||
      (networkTypesPromise = getInstance().get(`/network_types`)));
    return response.data;
  } catch (e) {
    Logger.log('Error while get network types list', e);
    return [];
  }
};

export const TrafficTypes = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (trafficTypesPromise ||
      (trafficTypesPromise = getInstance().get(`/source_traffic_types`)));

    return response.data;
  } catch (e) {
    Logger.log('Error while get traffic types list', e);
    return [];
  }
};

export const Manufacturers = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (manufacturersPromise ||
      (manufacturersPromise = getInstance().get(`/manufacturers`)));

    return response.data;
  } catch (e) {
    Logger.log('Error while get manufacturers list', e);
    return [];
  }
};

export const DeviceOs = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (deviceOsPromise ||
      (deviceOsPromise = getInstance().get(`/device_os`)));

    return response.data;
  } catch (e) {
    Logger.log('Error while get device os list', e);
    return [];
  }
};

export const DistrictCodes = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<DistrictCodeDTO[]> = await (districtCodesPromise ||
      (districtCodesPromise = getInstance().get(`/district_codes`)));

    return response.data.map((d) => convertDistrictCodeFromDTO(d));
  } catch (e) {
    Logger.log('Error while get district codes list', e);
    return [];
  }
};

export const SenateStates = async (): Promise<LocationState[]> => {
  try {
    const response: AxiosResponse<LocationStateDTO[]> = await (senateStatesPromise ||
      (senateStatesPromise = getInstance().get(`/list/senate_states`)));

    return response.data.map((d) => convertLocationStateFromDTO(d));
  } catch (e) {
    Logger.log('Error while get senate states list', e);
    return [];
  }
};

export const HouseStates = async (): Promise<LocationState[]> => {
  try {
    const response: AxiosResponse<LocationStateDTO[]> = await (houseStatesPromise ||
      (houseStatesPromise = getInstance().get(`/list/house_states`)));

    return response.data.map((d) => convertLocationStateFromDTO(d));
  } catch (e) {
    Logger.log('Error while get house states list', e);
    return [];
  }
};

type SenatasResponse = {
  id: number;
  geo_code: string;
  district_name: string;
  district_id: string;
};

export const SenatesNew = async (stateId: number): Promise<Option[]> => {
  try {
    const response: AxiosResponse<SenatasResponse[]> = await getInstance().get(
      `/senate_districts/${stateId}`,
    );

    return response.data.map((i) => ({
      value: i.id,
      label: i.district_name,
    }));
  } catch (e) {
    Logger.log('Error while get creative types', e);
    return [];
  }
};

export const HousesNew = async (stateId: number): Promise<Option[]> => {
  try {
    const response: AxiosResponse<SenatasResponse[]> = await getInstance().get(
      `/house_districts/${stateId}`,
    );

    return response.data.map((i) => ({
      value: i.id,
      label: i.district_name,
    }));
  } catch (e) {
    Logger.log('Error while get creative types', e);
    return [];
  }
};

export const DmaCodes = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<DmaCodeDTO[]> = await (dmaCodesPromise ||
      (dmaCodesPromise = getInstance().get('/dma_codes')));

    return response.data.map((i: DmaCodeDTO) => convertDmaCodesFromDTO(i));
  } catch (e) {
    Logger.log('Error while get countries', e);
    return [];
  }
};
